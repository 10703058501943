@font-face {
  font-family: 'Gilroy';
  src: url(../public/fonts/Gilroy-Medium.woff2) format('woff2'),
      url(../public/fonts/Gilroy-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url(../public/fonts/Gilroy-SemiBold.woff2) format('woff2'),
      url(../public/fonts/Gilroy-SemiBold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
